<template>
  <div>
    <SoundPlayer src="/sounds/pop.mp3" ref="sound_pop" :volume="0.33"/>
    <SoundPlayer src="/sounds/start.mp3" ref="sound_start" :volume="0.21"/>
    <SoundPlayer src="/sounds/draw1.mp3" ref="sound_draw1" :volume="0.13"/>
    <SoundPlayer src="/sounds/draw2.mp3" ref="sound_draw2" :volume="0.13"/>
    <SoundPlayer src="/sounds/guess.mp3" ref="sound_guess" :volume="0.21"/>
  </div>
</template>

<script>
export default {
  name: 'SoundManager',

  methods: {
    interact() {
      this.$refs.sound_draw1.interact();
      this.$refs.sound_draw2.interact();
      this.$refs.sound_guess.interact();
      this.$refs.sound_pop.interact();
      this.$refs.sound_start.interact();
    },

    ready(n) {
      this.$refs.sound_pop.play(0.9 + n / 10.0);
    },

    start() {
      this.$refs.sound_start.play(1.0);
    },

    draw() {
      if (this.randBetween(0, 1) === 0)
        this.$refs.sound_draw1.play(1.0);
      else
        this.$refs.sound_draw2.play(1.0);
    },

    guess() {
      this.$refs.sound_guess.play(1.0);
    },
  },

  components: {
    SoundPlayer: () => import('./SoundPlayer'),
  },
}
</script>